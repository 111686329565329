import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "home",
		component: Home,
	},
	{
		path: "/news/:slug",
		name: "news",
		component: () => import("../views/News.vue"),
	},
	{
		path: "/about",
		name: "about",
		component: () => import("../views/About.vue"),
	},
	{
		path: "/services",
		name: "services",
		component: () => import("../views/Services.vue"),
	},
	{
		path: "/service/:slug",
		name: "service",
		component: () => import("../views/Service.vue"),
	},
	{
		path: "/projects",
		name: "projects",
		component: () => import("../views/Projects.vue"),
	},
	{
		path: "/download",
		name: "download",
		component: () => import("../views/Download.vue"),
	},
	{
		path: "/jobs",
		name: "jobs",
		component: () => import("../views/Jobs.vue"),
	},
	{
		path: "/job/:id",
		name: "job",
		component: () => import("../views/Job.vue"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("../views/Contact.vue"),
	},
];

const router = new VueRouter({
	mode: "history",
	routes: routes,
});

export default router;