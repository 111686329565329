<template>
<footer>
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="widget">
                    <h5 class="widgetheading">Our Contacts</h5>
                    <address v-html="cms.c_page_address"></address>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="widget">
                    <h5 class="widgetheading">&nbsp;</h5>
                    <address v-html="cms.c_page_address2"></address>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="widget">
                    <h5 class="widgetheading">&nbsp;</h5>
                    <address v-html="cms.c_page_address3"></address>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="widget">
                    <h5 class="widgetheading">Quick Links</h5>
                    <ul class="link-list">
                        <li>
                            <router-link to="/">Home</router-link>
                        </li>
                        <li>
							<router-link to="/about">About</router-link>
                        </li>
                        <li>
                            <router-link to="/services">Services</router-link>
                        </li>
                        <li>
                            <router-link to="/projects">Projects</router-link>
                        </li>
                        <li>
                            <router-link to="/download">Download</router-link>
                        </li>
                        <li>
                            <router-link to="/jobs">Career</router-link>
                        </li>
                        <li>
                            <router-link to="/contact">Contact</router-link>
                        </li>
                        <li v-if="cms.l_facebook">
                            <a class="fa fa-facebook" data-placement="top" :href="cms.l_facebook" title="Facebook"> &nbsp; Facebook</a>
                        </li>
                        <li v-if="cms.l_twitter">
                            <a class="fa fa-twitter" data-placement="top" :href="cms.l_twitter" title="Twitter"> &nbsp; Twitter</a>
                        </li>
                        <li v-if="cms.l_linkedin">
                            <a class="fa fa-linkedin" data-placement="top" :href="cms.l_linkedin" title="Linkedin"> &nbsp; Linkedin</a>
                        </li>
                        <li v-if="cms.l_pinterest">
                            <a class="fa fa-pinterest" data-placement="top" :href="cms.l_pinterest" title="Pinterest"> &nbsp; Pinterest</a>
                        </li>
                        <li v-if="cms.l_googleplus">
                            <a class="fa fa-google-plus" data-placement="top" :href="cms.l_googleplus" title="Google+"> &nbsp; Google+</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="sub-footer" style="background: linear-gradient(to top, #7b5519, #aa7938 52%, #d29851); padding-top: 10px; padding-bottom: 10px;">
        <div class="container">
            <div class="row" style="margin-bottom: 0px;">
                <div class="col-lg-12">
                    <div class="copyright" style="display: inline-block;">
                        <p>
							<span>&copy; {{ new Date().getFullYear() }} All right reserved. By</span> <router-link to="/">{{ $appName }}</router-link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<a href="https://wa.me/971506696720?text=" target="_blank" style="position: fixed; bottom: 15px; right: 15px; width: 56px; z-index: 999;">
		<img width="100%" src="../assets/wa.png" />
	</a>
</footer>
</template>

<script type="text/javascript" scoped>
import { mapState } from "vuex";

export default {
	name: "Footer",
	computed: mapState([
		"cms",
	]),
}
</script>

<style type="text/css" scoped>
</style>