import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		global: {
			appName: "Mussafah Steel & Metallic Works",
			apiURL: "http://musaffahsteel.com/panel/api/",
		},
		cms: {
			"s_name": "",
			"s_contact_number": "",
			"s_email": "",
			"s_logo": "",
			"s_icon": "",
			"s_youtube": "",
			"c_map_address": "",
			"c_page_address": "",
			"c_page_address2": "",
			"c_page_address3": "",
			"l_facebook": "",
			"l_twitter": "",
			"l_linkedin": "",
			"l_pinterest": "",
			"l_googleplus": "",
		},
	},
	mutations: {
		cms(state, payload){
			state.cms = payload;
		},
	},
	actions: {
		cms(context){
			axios({
				method: "get",
				url: context.state.global.apiURL+"get_cms",
			}).then((res) => {
				context.commit("cms", res.data);
			});
		},
	},
});