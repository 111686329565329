<template>
    <div id="app">
        <div class="home-page" id="wrapper">
            <Header></Header>
            <router-view></router-view>
            <Footer></Footer>
        </div>
    </div>
</template>

<script type="text/javascript">
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapState } from "vuex";

export default{
	components: {
		Header,
		Footer,
	},
	computed: mapState([
		"cms",
	]),
	mounted(){
		this.$store.dispatch("cms").then(() => {
			this.$jquery("title").html(this.$appName);
			var href = this.$uploadsURL+"logo/"+this.cms.s_icon;
			this.$jquery("#site-icon").attr("href", href);
		});
	},
}
</script>

<style type="text/css">
#wrapper{
	padding-top: 32px;
}
.nav.navbar-nav a.router-link-exact-active{
	color: #FFFFFF !important;
    background-image: linear-gradient(to top, #7B5519, #AA7938 52%, #D29851) !important;

}
.nav.navbar-nav a.router-link-exact-active:before{
	content: "";
    position: absolute;
    height: 44px;
    width: 86%;
    background-color: #171717;
    z-index: -1;
    bottom: -6px;
    right: -6px;
}
.fa{
	margin-right: 5px;
}
.btn-sm{
	padding: 5px 10px;
}
</style>