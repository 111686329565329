<template>
    <div class="home">
		<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
        <section class="hero-text">
            <div class="container">
                <div class="row">
					<div class="col-lg-8 col-md-12">
                        <div v-html="about.short_description"></div>
                        <router-link to="/about" class="btn btn-purchase">Read More</router-link>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <h1>&nbsp;</h1>
                        <iframe style="width: 100%;" :src="'https://www.youtube.com/embed/'+cms.s_youtube" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
        <Services :isHome="'YES'"></Services>
		<Testimonials></Testimonials>
    </div>
</template>

<script type="text/javascript" scoped>
import { mapState } from "vuex";
import Services from "@/components/Services.vue";
import Testimonials from "@/components/Testimonials.vue";

export default{
	name: "HomeView",
	components: {
		Services,
		Testimonials,
	},
	computed: mapState([
		"cms",
	]),
	data(){
		return {
			about: {
				short_description: "",
			},
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_about",
		}).then((res) => {
			this.about = res.data;
			this.$refs.topProgress.done();
		});
	}
}
</script>

<style type="text/css" scoped>
</style>