<template>
	<div>
		<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
		<div class="row" style="margin-bottom: 0px;">
			<div class="col-lg-8 col-md-8 col-sm-8" style="padding: 0px;">
				<section v-if="slider.length" id="banner">
					<carousel autoHeight="" :items="1" :loop="true" :autoplay="true" :nav="false">
						<div v-for="(slide, index) in slider" :key="index">
							<div class="owl-overlay"></div>
							<img class="owl-image" :alt="slide.heading" :src="$uploadsURL+'home_slider/'+slide.image">
							<div class="owl-caption wow fadeInLeft animated">
								<h1 v-html="slide.heading"></h1>
								<p v-html="slide.subheading"></p>
							</div>
						</div>
					</carousel>
				</section>
			</div>
			<div class="col-lg-4 col-md-4 col-sm-4" style="padding: 0px;">
				<div id="marquee-container" v-if="news.length">
					<list-marquee>
						<router-link class="marquee-item" v-for="(row, index) in news" :key="index" :to="{name: 'news', params: {slug: row.slug}}">
							<i class="fa fa-arrow-right"></i> 
							<span v-html="row.title"></span>
							<p><i class="fa fa-clock-o"></i>{{ row.created }}</p>
						</router-link>
					</list-marquee>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
import carousel from "vue-owl-carousel";
import ListMarquee from "vue-marquee-list";

export default{
	name: "Banner",
	components: {
		carousel,
		ListMarquee,
	},
	data(){
		return {
			slider: [],
			news: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_banner",
		})
		.then((res) => {
			this.$refs.topProgress.done();
			this.slider = res.data.slider;
			this.news = res.data.news;

		});
	}
}
</script>

<style type="text/css">
#banner, #marquee-container{
	margin-top: 16px;
}
#marquee-container{
    /*position: absolute;*/
    /*top: 30px;*/
    /*bottom: 30px;*/
    /*right: 30px;*/
    /*z-index: 999;*/
    /*width: 30%;*/
    border-bottom: 4px solid #BE8844;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFFFFF;
    text-align: left;
    overflow: hidden;
    height: 420px;
}
#marquee-container a.marquee-item{
    display: block;
	padding-left: 30px;
	border-bottom: 1px dashed #555555;
    margin-bottom: 15px;
    padding-bottom: 15px;
    text-decoration: none;
    color: #FFFFFF;
}
#marquee-container i{
    color: #BE8844;
}
#marquee-container p{
    color: #777777;
} 
/* Home Slider */
#banner{
	position: relative;
}
#banner .owl-carousel{
	position: relative;
	background: #FFFFFF;
	padding: 0px;
	zoom: 1;
}
#banner .owl-overlay-rm{
    position: absolute;
    background: rgba(0, 0, 0, 0.48);
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
}
#banner .owl-image{
	height: 420px;
}
#banner .owl-caption{
	position: absolute;
	z-index: 2;
	zoom: 1;
	background-color: transparent;
	color: #FFFFFF;
	left: 30px;
	right: 35%;
    bottom: 21%;
}
#banner .owl-caption h3{
	color: #FFFFFF;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 94px;
}
#banner .owl-caption p{
    font-size: 22px;
}
#banner .owl-dots{
    position: absolute;
    bottom: 15px;
    left: 30px;
    z-index: 2;
}
#banner .owl-dot span{
	display: block;
	width: 25px;
	height: 10px;
	height: 11px;
	border-radius: 0;
	background: #FFFFFF;
	background: rgba(255, 255, 255, 1); 
}
#banner .owl-dot.active span{
	background-image: linear-gradient(to top, #7B5519, #AA7938 52%, #D29851);
	box-shadow: none;
}
@media(max-width: 767px){
	#banner .owl-image{
		max-height: 240px;
	}
	#banner .owl-caption{
        display: none;
    }
}
</style>