<template>
<header>
	<nav style="background-color: #171717; height: 48px; display: flex; justify-content: center; flex-direction: column; position: fixed; top: 0; left: 0; right: 0; z-index: 9999;">
		<div class="container">
			<a style="text-decoration: none; color: #BE8844; font-size: 24px; font-weight: bold;" href="javascript: void(0);">
				{{ cms.s_name }}
			</a>
			<a class="pull-right" style="text-decoration: none; color: #fff; padding-left: 15px;" :href="'mailto:' + cms.s_email">
				<i class="fa fa-envelope"></i> {{ cms.s_email }}
			</a>
			<a class="pull-right" style="text-decoration: none; color: #fff;" :href="'tel:' + cms.s_contact_number">
				<i class="fa fa-phone"></i> {{ cms.s_contact_number }}
			</a>
		</div>
	</nav>
	<div class="navbar navbar-default navbar-static-top">
		<div class="container">
			<div class="navbar-header">
				<button class="navbar-toggle" data-target=".navbar-collapse" data-toggle="collapse" type="button">
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<a class="navbar-brand" href="" style="margin-top: 10px;">
					<img alt="" :src="$uploadsURL+'logo/'+cms.s_logo" style="max-height: 80px;">
				</a>
            </div>
			<div class="navbar-collapse collapse">
				<ul class="nav navbar-nav">
					<li>
						<router-link to="/">Home</router-link>
					</li>
					<li>
						<router-link to="/about">About</router-link>
					</li>
					<li>
						<router-link to="/services">Services</router-link>
					</li>
					<li>
						<router-link to="/projects">Projects</router-link>
					</li>
					<li>
						<router-link to="/download">Download</router-link>
					</li>
					<li>
						<router-link to="/jobs">Career</router-link>
					</li>
					<li>
						<router-link to="/contact">Contact</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<Banner v-if="$route.name == 'home'"></Banner>
</header>
</template>

<script type="text/javascript" scoped>
import { mapState } from "vuex";
import Banner from "@/components/Banner.vue";

export default {
	name: "Header",
	components: {
		Banner,
	},
	computed: mapState([
		"cms",
	]),
}
</script>

<style type="text/css" scoped>
</style>