import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import jquery from "jquery";
import vueTopprogress from "vue-top-progress";
import Toasted from "vue-toasted";

Vue.use(vueTopprogress);
Vue.use(Toasted, {
	position: "top-center", 
	duration : 5000,
});

Vue.config.productionTip = false;

Vue.prototype.$appName = "Mussafah Steel & Metallic Works";
Vue.prototype.$apiURL = "http://musaffahsteel.com/panel/api/";
Vue.prototype.$uploadsURL = "http://musaffahsteel.com/panel/assets/uploads/";
Vue.prototype.$barColor = "#FFFFFF";
Vue.prototype.$barHeight = 3;
Vue.prototype.$axios = axios;
Vue.prototype.$jquery = jquery;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");