<template>
    <section id="content">
		<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div v-if="isHome == 'YES' && rows.length > 0" class="row">
                        <div class="col-md-12">
                            <div class="aligncenter">
                                <h2 class="text-center">Our Services</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div v-for="row in rows" :key="row.id" class="col-lg-3 col-md-3 col-sm-4 col-xs-6">
							<router-link :to="{name: 'service', params: {slug: row.slug}}">
								<div class="service-div" :style="{ backgroundImage: 'url('+$uploadsURL+'services/'+row.image+')' }">
									<div class="service-title">
										{{ row.title }}
									</div>
								</div>
							</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script type="text/javascript" scoped>
export default{
	name: "Services",
	props: {
		isHome: String,
	},
	data(){
		return {
			rows: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_services/"+this.isHome,
		})
		.then((res) => {
			this.rows = res.data;
			this.$refs.topProgress.done();
		});
	}
}
</script>

<style type="text/css" scoped>
.service-div{
	margin-bottom: 30px;
	height: 256px;
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-top: 4px solid #BE8844;
}
.service-title{
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.6);
	color: #FFFFFF;
	text-align: center;
	padding: 10px;
}
</style>