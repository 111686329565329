<template>
<div id="projects">
	<vue-topprogress ref="topProgress" :color="$barColor" :height="$barHeight"></vue-topprogress>
    <section v-if="rows.length" class="testimonial-area">
        <div class="testimonial-solid">
            <div class="container">
                <div class="testi-icon-area">
                    <div class="quote fa fa-microphone" style="font-style: italic">
                    </div>
                </div>
                <div class="carousel slide" data-ride="carousel" id="carousel-testimonials">
                    <ol class="carousel-indicators">
                        <li v-for="(row, index) in rows" :key="row.id" :class="{ active: (index == 0) }" :data-slide-to="index" data-target="#carousel-testimonials">
                            <a href="javascript: void(0);"></a>
                        </li>
                    </ol>
                    <div class="carousel-inner">
                        <div v-for="(row, index) in rows" :key="row.id" :class="{ active: (index == 0), item: true }">
                            <p>{{ row.quote }}</p>
                            <p style="font-weight: bold">
                                - {{ row.by }} -
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</template>

<script type="text/javascript" scoped>
export default{
	name: "Testimonials",
	data(){
		return {
			rows: [],
		}
	},
	mounted(){
		this.$refs.topProgress.start();
		this.$axios({
			method: "get",
			url: this.$apiURL+"get_testimonials",
		})
		.then((res) => {
			this.rows = res.data;
			this.$refs.topProgress.done();
		});
	}
}
</script>

<style type="text/css" scoped>
</style>